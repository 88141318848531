




























import { Vue, Component } from "vue-property-decorator";
import { InnoChina } from "@/components/home_2022/innoChina";
import { Organizers } from "@/components/home_2022/organizers";
import { WorkBasic } from "@/components/home_2022/workBasic";

import WhatsBesttt from "@/components/home_2022/WhatsBesttt.vue";
@Component({
    components: {
        InnoChina,
        Organizers,
        WorkBasic, WhatsBesttt,
    }
})
export default class AboutActivity extends Vue { }
