import { render, staticRenderFns } from "./WhatsBesttt.vue?vue&type=template&id=53b48e73&"
import script from "./WhatsBesttt.vue?vue&type=script&lang=ts&"
export * from "./WhatsBesttt.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports