












import { Vue, Component } from "vue-property-decorator";
@Component({})
export default class ContItemContent_BoxItem extends Vue {}
