



































































import { Vue, Component } from "vue-property-decorator";
import ContItem from "@/components/home/contItem/ContItem.vue";
import ContItemTitle from "@/components/home/contItem/ContItem_Title.vue";
import ContItemContent from "@/components/home/contItem/ContItem_content.vue";
import BoxItem from "@/components/home/contItem/ContItem_Content_boxItem.vue";
@Component({
    components: {
        "cont-item": ContItem,
        "cont-title-pro": ContItemTitle,
        "cont-content-box": ContItemContent,
        "box-item": BoxItem
    }
})
export default class Workbasic extends Vue {
    /**活动时间线,state:1已进行,0未进行 */
    timeline_data: {} = {
        "2020": [
            { title: "启动案例征集", time: "7月", state: "1" },
            { title: "首次专家会", time: "8.18", state: "1" },
            { title: "案例征集截止", time: "8.27", state: "1" },
            { title: "线上案例筛选", time: "8.28-9.1", state: "1" },
            { title: "第二次专家会", time: "9.2", state: "1" },
            { title: "榜单发布", time: "9.8", state: "1" }
        ],
        "2021": [
            { title: "案例征集", time: "7.15-8.25", state: "1" },
            // { title: "首次专家会", time: null, state: "0" },
            { title: "征集截止", time: "8.25", state: "1" },
            { title: "线上案例筛选", time: "8.26-8.31", state: "1" },
            { title: "专家终审会", time: "9.1", state: "1" },
            { title: "榜单发布", time: "9.6", state: "1" }
        ],
        "2022": [
            { title: "征集评审", time: "10月", state: "1" },
            { title: "成果发布", time: "11月", state: "0" },
            { title: "后续推广", time: "11月后", state: "0" }
        ]
    };
}
